@tailwind base;
@tailwind components;
@tailwind utilities;

/* Regular */
@font-face {
  font-family: 'Lucida Sans';
  src: url('assets/fonts/LSANS.TTF') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Bold */
@font-face {
  font-family: 'Lucida Sans';
  src: url('assets/fonts/LTYPEBO.TTF') format('truetype');
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Lucida Sans', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #101014; 
}


/** Formato de Cryptotradex **/
body {
  font-family: "Days One";
  font-style: normal;
}

.bg-main-menu {
  background: rgb(6,11,38);
  background: linear-gradient(90deg, rgba(36, 36, 36, 0.9) 45.5%, rgba(153, 69, 255, 0.8) 61.5%, rgba(36, 36, 36, 0.9) 81%);
}
.custom-button {
  background-color: #4584ff;
}

.custom-button-shadow-two{
  filter: drop-shadow(0px 0px 10px rgba(0, 51, 191, 0.6));
}
.custom-button-shadow{
  border: 2px solid #4584ff;
}
.font2{
  font-family: "Courier Prime" !important;
  font-style: normal;
}
label{
  font-family: "Courier Prime" !important;
  font-style: normal;
  font-weight: 700;
}
input:not([type="tel"]) {
  border: 2px solid #4584ff !important;
  padding: 1rem !important;
  border-radius: 0.5rem !important;
  background-color: #161616 !important;
  font-family: "Courier Prime" !important;
  font-style: normal;
  width: 100%;
  color: white !important;
}

.inputphone{
  border: 2px solid #4584ff !important;
  padding: 0.5rem !important;
  border-radius: 0.5rem !important;
  background-color: #161616 !important;
  font-family: "Courier Prime" !important;
  font-style: normal;
  width: 100%;
  color:white !important;
  font-size: 100% !important;
  padding-left: 1rem;
}
.inputphone input{
  border: none !important;
  background: transparent !important;
  color:white !important;
  height: auto !important;
}
.react-international-phone-country-selector-dropdown{
  background-color: #161616 !important;
  color:white !important;
}
.react-international-phone-country-selector-dropdown ul li::placeholder{
  background-color: #161616 !important;
}
.react-international-phone-country-selector-button{
  background-color: #161616 !important;
  border: none !important;
}
/** Formato de Gladiaverse **/
.bg-gladiaverse{
  background: rgb(6,11,38);
  background: linear-gradient(90deg, rgba(6,11,38,1) 0%, rgba(22,27,51,1) 95%, rgba(26,31,55,0.9613095238095238) 100%);
}
.bg-gladiaverse2{
  background: rgb(6,11,38);
  background: linear-gradient(-90deg, rgba(6,11,38,1) 0%, rgba(22,27,51,1) 95%, rgba(26,31,55,0.9613095238095238) 100%);
}

.border-gladiaverse {
  border: solid 1.5px transparent;
  border-radius: 50px;
  background:
      linear-gradient( #17171a, #17171a) padding-box,
      linear-gradient(92.7deg, #582CFF, #333131) border-box;
}
canvas{
  max-height: 350px !important;
}

.carta-box:hover .carta {
  transform: rotateY(180deg);
}

.carta {
  transform-style: preserve-3d;
  transition: all 0.5s linear;
  width: 100%;
}

.cara {
  backface-visibility: hidden;
  /*background-color: #29C7E2;*/
  position: absolute;
  /*
  width: 100%;
  min-height: 316px;*/
}

.cara.detras {
  transform: rotateY(180deg);
  color: white;
  /*background-color: #29C7E2;*/
}

.shim-green {
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(
    90deg,
    rgb(26, 247, 158) 0,
    rgb(153, 69, 255) 100%
  );
}
.shim-green::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgb(26, 247, 158) 0,
    rgb(153, 69, 255) 100%
  );
  animation: shimmer 2s ease-out infinite;
  content: "";
}


@keyframes shimmer {
  100% {
    transform: translateX(0%);
    opacity: 0;
  }
}

.line-group{
  height: 2px;
  background: #0033bf;
  box-shadow: -1px 2px 40px 6px rgba(0, 51, 191, 0.75);
}

.pulse {
  animation: pulse 1s infinite ease-in-out alternate;
}
@keyframes pulse {
  from { transform: scale(0.8); }
  to { transform: scale(1.2); }
}


.bg-coutndown{
  background-color: #171029;
background-image: linear-gradient(43deg, #0D122C 0%, #0D122C 60%, #074dd8 100%);
}

.bg-result{
  background-color: #171029;
background-image: linear-gradient(300deg, #0D122C 0%, #0D122C 60%, #582058 100%);
}


.data-popper-arrow {
  position: absolute;
  top: 100%; /* This positions the arrow at the bottom of the popover */
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid rgb(87, 118, 232); /* Color of the popover background */
  border-bottom: none;
}

  /* Animation class for the border */
  .animate-border {
    animation: blink-border 2s infinite;
    border-color: rgb(87, 118, 232);;
  }

  /* Keyframes for the blinking border effect */
  @keyframes blink-border {
    0% {
      box-shadow: 0 0 10px rgb(87, 118, 232);
    }
    50% {
      box-shadow: 0 0 12px rgb(87, 118, 232);;
    }
    100% {
      box-shadow: 0 0 10px rgb(87, 118, 232);;
    }
  }

  
.border-lime-600, .border-lime-300{
  border-color: #4584ff !important ;
}

.text-lime-400, .text-lime-300, .text-lime-500{
  color: #4584ff !important ;
}

.bg-lime-600, .bg-lime-300{
  background: linear-gradient(223.64deg, #1AF79E 0%, #9945FF 97.74%);
}